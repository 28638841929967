/* @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500;700&family=Lexend:wght@400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import 'rsuite/dist/rsuite.css';
@import 'reset.css';
@import 'vars.css';
@import 'loader.css';
@import 'btn-animate.css';

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

body::-webkit-scrollbar {
  /*display: none;*/
  /*width: 6px;*/
  /*height: 6px;*/
}


html,
body {
  font-family: 'Onest', sans-serif;
  background-color: #ededea;
  font-size: 16px;
  line-height: 1.24;
  color: var(--primary-3);
}


div::-webkit-scrollbar, ul::-webkit-scrollbar, td::-webkit-scrollbar {
  display: none;
}

span {
  color: var(--primary-3);
}

h1 {
  font-size: 40px;
  color: var(--primary-3);
  line-height: 1.2;
  text-transform: capitalize;
}

h2 {
  font-size: 16px;
  line-height: 1;
  color: var(--primary-3);
  text-transform: uppercase;
}

p {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
}

p + p {
  margin: 0;
}

tr {
  padding: 8px 12px;
  background-color: var(--white);
}

tr:hover td {
  background: #eff6ff;
}

th {
  position: relative;
  vertical-align: middle;

  color: #697387;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  background-color: var(--white);
}

th:first-child {
  text-transform: capitalize;
}

td {
  vertical-align: middle;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  max-width: 400px !important;

  color: var(--primary-3, #0b1c3e);
  background-color: var(--white);
}

a {
  color: var(--blue);
}

a:hover {
  color: var(--primary-4);
}

input[type='checkbox'] {
  cursor: pointer;
}

iframe[src='about:blank'] {
  display: none;
  width: 70vw !important;
  height: 80vh !important;
  top: 20% !important;
  left: 50% !important;
  transform: translateX(-50%);
}

.wrap-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;
  padding-top: 100px;
  font-size: 32px;
}

.wrap-error p {
  font-size: 20px;
}

.wrap-error span {
  display: inline-block;
  color: var(--blue);
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #e7eadd inset;
}

input[type='checkbox'] {
  position: relative;
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #ced2d8;
  cursor: pointer;
  border-radius: 2px;
}

input[type='checkbox']:checked {
  background-color: var(--primary-4);
}

input[type='checkbox']:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 3px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type='checkbox']:checked:before {
  display: block;
}

/* scrollbar styles */
.scroll {
  overflow: auto;
}

.scroll::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 6px;
}


.scroll-shown-always::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}


@media screen and (min-width: 768px) {
  .scroll-onbigsizes {
    overflow: scroll;
  }

  .scroll-shown::-webkit-scrollbar {
    display: block !important;
  }

  .scroll-shown::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}
